import { Application, Controller } from "stimulus"

export default class AddPlaceImagesController extends Controller {
  
  static targets = ["tourCardImage", "tourCardFlagContainer", "tourCardGuruImage", "tourCardAvailabilityFlagContainer"]
  static values = { imagesUrl: Array, flagsUrl: Object, guruImagesUrl: Array }

  globalCount = 0;
  tourObserver = this.tourObserver();
  onTopPage = window.scrollY < 500;

  connect() {
    if (this.onTopPage) {
      this.appendTourImages()
      this.nextObserver();
    } else {
      this.appendAllTourImages()
    }
  }

  tourObserver() {
    return new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.intersectionRatio > 0.2) {
          this.globalCount++;
          this.appendTourImages();
          this.nextObserver();
          this.tourObserver.unobserve(entry.target);
        }
      });
    }, { threshold: 0.25 });
  }

  appendAllTourImages() {
    this.tourCardImageTargets.forEach(() => {
      this.appendTourImages();
      this.globalCount++;
    });
  }

  appendTourImages() {
    if (this.hasNextTour()) {
      this.appendTourImage();
      this.appendFlagImages();
      this.appendAvailabilityFlagImages();
      this.appendGuruImage();
    }
  }
  
  appendTourImage() {
    const imgSrc = this.imagesUrlValue[this.globalCount];
    this.tourCardImageTargets[this.globalCount].src = imgSrc;
  }
  
  appendFlagImages() {
    const tourCardFlagContainer = this.tourCardFlagContainerTargets[this.globalCount]
    tourCardFlagContainer?.querySelectorAll("[data-type=tour-card-flag]").forEach(flag => {
      const imgStyleUrl = `url(${this.flagsUrlValue[flag.dataset.locale]})`
      flag.style.backgroundImage = imgStyleUrl;
    });
  }

  appendAvailabilityFlagImages() {
    const tourCardAvailabilityFlagContainer = this.tourCardAvailabilityFlagContainerTargets[this.globalCount]
    tourCardAvailabilityFlagContainer?.querySelectorAll("[data-type=tour-card-flag]").forEach(flag => {
      const imgStyleUrl = `url(${this.flagsUrlValue[flag.dataset.locale]})`
      flag.style.backgroundImage = imgStyleUrl
    })
  }
  
  appendGuruImage() {
    const imgStyleUrl = `url(${this.guruImagesUrlValue[this.globalCount]})`
    this.tourCardGuruImageTargets[this.globalCount].style.backgroundImage = imgStyleUrl;
  }

  nextObserver() {
    if (this.hasNextTour()) {
      this.tourObserver.observe(this.tourCardImageTargets[this.globalCount]);
    }
  }

  hasNextTour() {
    return this.globalCount <= this.tourCardImageTargets.length - 1;
  }
}

const application = Application.start()
application.register("add-place-images", AddPlaceImagesController)
