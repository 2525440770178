window.injectScript = (src, async = false) => {
  let script = document.createElement('script');
  script.src = src;
  script.async = async;
  document.body.appendChild(script);
};

window.injectLink = (href) => {
  let link = document.createElement('link');
  link.media = "screen";
  link.as = "style";
  link.rel = "stylesheet";
  link.href = href;
  document.body.appendChild(link);
};