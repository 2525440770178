// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.initMap = function() {}

import '../vendor/application_helper';
import '../vendor/cookies_static';
import "../partials/detect_browser"
import "../partials/track_custom_event"
import '../controllers/static_places_controller';
import "../controllers/fake_link_controller"
import '../controllers/view_tour_controller';
import '../controllers/add_place_images_controller';
import '../controllers/add_place_suggested_places_controller';
import './components/scrollable_container_controller'
import './components/expandable_container_controller'