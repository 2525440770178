import { Application, Controller } from "stimulus";

export default class StaticPlacesController extends Controller {
  static targets = ["navbarContainer", "cookieBanner", "searchPlaceDate", "bottombarContainer"];
  static values = {placeId: String, categoryPlaceTranslationId: {type: String, default: ""}, locale: String}

  smartBannerShowed = false;
  scrollPositionShowBanner = 200;

  connect() {
    this.initializeToggleNav();
    this.initializeTrackGuruHelpCenterButtonClicked();
  }

  navbarContainerTargetConnected() {
    this.fetchTemplate("navbarContainer");
  }

  bottombarContainerTargetConnected() {
    this.fetchTemplate("bottombarContainer");
  }

  cookieBannerTargetConnected() {
    this.fetchTemplate("cookieBanner");
    window.waitForDomElement("[data-cookies-notice-target='bannerCookiesContainer']", (bannerCookiesContainer) => {
      setTimeout(() => { bannerCookiesContainer.parentElement.classList.add('visible') }, 250);
      setTimeout(() => { this.cookieBannerManagement(bannerCookiesContainer); }, 1000);
    });
  }

  cookieBannerManagement(bannerCookiesContainer) {
    const bannerCookiesStaticContainer = bannerCookiesContainer.parentElement;
    const body = bannerCookiesStaticContainer.parentElement;
    body.insertBefore(bannerCookiesContainer, bannerCookiesStaticContainer);
    body.removeChild(bannerCookiesStaticContainer);
  }

  setCookieBanner() {
    const cookieTargets = ["editPrivacySettingsButton", "acceptAllCookiesButton"];
    cookieTargets.forEach(target => {
      const cookieButton = this.getNodeElement(`[data-cookies-notice-target='${target}']`);
      cookieButton?.classList.remove("invisible");
    });
  }

  getNodeElement(selector) {
    return document.querySelector(selector) || null;
  }

  renderTemplate(html, targetName) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    doc.head.querySelectorAll("link").forEach(headLink => window.injectLink(headLink.href));
    this[`${targetName}Target`].innerHTML = doc.body.innerHTML;
    this[`${targetName}Target`].dataset.rendered = "true";
    doc.head.querySelectorAll("script").forEach(headScript => window.injectScript(headScript.src, true));
    if (targetName === "cookieBanner") this.setCookieBanner();
  }

  async fetchTemplate(targetName) {
    if (this[`${targetName}Target`].dataset.rendered == "true") { return; }
    
    const path = targetName.replace("Container", "").replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase();

    fetch(`/dynamic_landing_data/${path}?place_id=${this.placeIdValue}&category_place_translation_id=${this.categoryPlaceTranslationIdValue}&lang=${this.localeValue}`, {
      method: "GET",
      headers: { "Content-Type": "application/json", }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.message) {
        console.warn(data.message);
      } else if (data[path]) {
        this.renderTemplate(data[path], targetName);
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  startProcessSearchPlaceDate() {
    this.addGonMoment();
    setTimeout(() => {
      this.fetchTemplate("searchPlaceDate");
      window.waitForDomInjected('search_with_suggestions_date', (_) => {
        setTimeout(() => { window.search_with_suggestions_date.startProcess(); }, 250);
      })
    }, 0);
  }

  startProcessDateRangePicker() {
    this.addGonMoment();
    setTimeout(() => {
      this.fetchTemplate("searchPlaceDate");
      window.waitForDomInjected('search_with_suggestions_date', (_) => {
        setTimeout(() => { 
          window.modal_bsht_date_range_picker.activate();
          window.date_range_picker.initializeDates()
        }, 250);
      })
    }, 0);
  }

  addGonMoment() {
    if(gon.moment == undefined) {
      gon.moment = require('moment')
    }
  }

  initializeToggleNav() {
    window.toggleNav = () => {
      const userNav = document.getElementById('mainSidenav');
      const isOpen = !userNav.classList.contains('side-nav-hidden');
      userNav.setAttribute('class', isOpen ? 'side-nav-hidden' : '');
      window.scrollTo(0, 0);
    }
  }

  initializeTrackGuruHelpCenterButtonClicked() {
    window.trackGuruHelpCenterButtonClicked = () => {
      window.rudderanalytics.track("Guru Help Center Button Clicked", {
        version: 0
      })
    }
  }
  
}

const application = Application.start();
application.register("static-places", StaticPlacesController);
