import { Application, Controller } from "stimulus"

export default class ExpandableContainerController extends Controller {

  static targets = [ "showHandler", "expandableContent" ]
  static values = { containerHeight: { type: Number, default: 80 } }

  initalOffsetHeight = this.expandableContentTarget.offsetHeight;

  connect() {
    this.checkExpandableContent()
  }


  checkExpandableContent() {
    if (this.initalOffsetHeight > this.containerHeightValue ) {
      this.expandableContentTarget.style.maxHeight = this.containerHeightValue;
    } else {
      this.expandableContentTarget.classList.remove('masked-content')
      this.showHandlerTarget.classList.add('d-none')
    }
  }

  toggleExpandable() {
    this.expandableContentTarget.style.maxHeight = "";
    this.expandableContentTarget.classList.remove('masked-content')
    this.showHandlerTarget.classList.add('d-none')
  }
}

const application = Application.start()
application.register("expandable-container", ExpandableContainerController)
